@font-face {
    font-family: "J&J Circular";
    font-style: normal;
    font-weight: 300;
    src: url(/static/media/CircularStd-Book.829012af.woff) format('WOFF');
}

@font-face {
    font-family: "J&J Circular";
    font-style: italic;
    font-weight: 300;
    src: url(/static/media/CircularStd-BookItalic.3c7409b8.woff) format('WOFF');
}

@font-face {
    font-family: "J&J Circular";
    font-style: normal;
    font-weight: 400;
    src: url(/static/media/CircularStd-Medium.381f87a9.woff) format('WOFF');
}

@font-face {
    font-family: "J&J Circular";
    font-style: italic;
    font-weight: 400;
    src: url(/static/media/CircularStd-MediumItalic.451b6f03.woff) format('WOFF');
}

@font-face {
    font-family: "J&J Circular";
    font-style: normal;
    font-weight: bold;
    src: url(/static/media/CircularStd-Bold.03e7ee16.woff) format('WOFF');
}

@font-face {
    font-family: "J&J Circular";
    font-style: italic;
    font-weight: bold;
    src: url(/static/media/CircularStd-BoldItalic.705bbdf2.woff) format('WOFF');
}

@font-face {
  font-family: "J&J Circular";
  font-style: normal;
  font-weight: 900;
  src: url(/static/media/CircularStd-Black.49611f29.woff) format('WOFF');
}

@font-face {
  font-family: "J&J Circular";
  font-style: italic;
  font-weight: 900;
  src: url(/static/media/CircularStd-BlackItalic.1c46c301.woff) format('WOFF');
}

